import emailjs from "@emailjs/browser";
import cx from "classnames";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { MdDoubleArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import styles from "./Career.module.css";

const workData = [
  { title: "Team Augmentation" },
  { title: "New Project" },
  { title: "Dedicated Team" },
  { title: "Custom Software Development" },
  { title: "Enterprise Software Development" },
];

const serviceData = [
  { title: "Startup" },
  { title: "Digital Transformation" },
  { title: "Artifical Intelligence" },
  { title: "Healthcare" },
  { title: "Ed tech" },
];

function Career() {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    work: "",
    service: "",
    message: "",
    "g-recaptcha-response": "",
  });

  const [error, setError] = useState({});
  const [delivered, setDelivered] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var err = {};
    if (data.name === "") {
      err["name"] = "Name is required";
      setDelivered("");
    }
    if (data.email === "") {
      err["email"] = "Email is required";
      setDelivered("");
    }

    if (data.work === "") {
      setDelivered("");
      err["work"] = "Work is required";
    }
    if (data.phone === "") {
      err["phone"] = "Phone number is required";
    } else if (data.phone.trim().length < 9 || data.phone.trim().length > 10) {
      setDelivered("");
      err["phone"] = "Phone number must be 10 digits";
    }
    if (data.service === "") {
      setDelivered("");
      err["service"] = "Service is required";
    }
    if (data.message === "") {
      setDelivered("");
      err["message"] = "Message is required";
    }
    if (data["g-recaptcha-response"] === "") {
      setDelivered("");
      err["captcha"] = "Captcha is required";
    }

    const isValidated = Object.keys(err).length === 0;
    setError(err);
    if (isValidated) {
      setLoading(true);
      console.log(data);
      emailjs.send("gmail", "template_yehl1ud", data, "UqlNjbGOEuuYt596p").then(
        (result) => {
          console.log(result.text);
          setData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            service: "",
            work: "",
            "g-recaptcha-response": "",
          });
          setLoading(false);
          setDelivered(
            "We appreciate you for reaching out. We received your message. We will get back to you by next working day."
          );
          window.grecaptcha.reset();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.contentWrapper}>
          <div className={styles.detailsContainer}>
            <h2 className={cx(styles.detailTitle, "title")}>Let's work together</h2>
            <p className={styles.detailsParagraph}>
              Please tell us a bit about you, your project, and how best to reach you. We’ll get
              right back to you.
            </p>
            <Link to="/getintouch/" className={styles.career}>
              Get in touch
              <MdDoubleArrow className={styles.icon} />
            </Link>
          </div>
          <form className={styles.container} autoComplete="off" onSubmit={handleSubmit}>
            <div className={styles.contentContainer}>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="name" className={styles.label}>
                    Name*
                  </label>
                  {error.name && <span className={styles.error}>{error.name}</span>}
                </div>
                <input
                  type="text"
                  name="name"
                  id="unique-name"
                  className={styles.input}
                  value={data.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </div>

              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="phone" className={styles.label}>
                    Phone Number*
                  </label>
                  {error.phone && <span className={styles.error}>{error.phone}</span>}
                </div>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  className={styles.input}
                  value={data.phone}
                  onChange={(e) => {
                    setData({ ...data, phone: e.target.value });
                  }}
                />
              </div>

              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="email" className={styles.label}>
                    Email*
                  </label>
                  {error.email && <span className={styles.error}>{error.email}</span>}
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={styles.input}
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="Work" className={styles.label}>
                    Work*
                  </label>
                  {error.work && <span className={styles.error}>{error.work}</span>}
                </div>
                <select
                  name="work"
                  id="work"
                  className={cx(styles.input, styles.select)}
                  value={data.work}
                  onChange={(e) => {
                    setData({ ...data, work: e.target.value });
                  }}
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>
                  {workData.map((work, index) => (
                    <option key={index} value={work.title}>
                      {work.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="service" className={styles.label}>
                    Service*
                  </label>
                  {error.service && <span className={styles.error}>{error.service}</span>}
                </div>
                <select
                  name="service"
                  id="service"
                  className={cx(styles.input, styles.select)}
                  value={data.service}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setData({ ...data, service: e.target.value });
                  }}
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>
                  {serviceData.map((service, index) => (
                    <option key={index} value={service.title}>
                      {service.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="message" className={styles.label}>
                    About your project*
                  </label>
                  {error.message && <span className={styles.error}>{error.message}</span>}
                </div>
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  className={styles.textarea}
                  rows={4}
                  value={data.message}
                  onChange={(e) => setData({ ...data, message: e.target.value })}
                />
              </div>
              <div className={styles.captchaContainer}>
                <ReCAPTCHA
                  className={styles.captcha}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={(e) => setData({ ...data, "g-recaptcha-response": e })}
                />
                {error.captcha && <span className={styles.error}>{error.captcha}</span>}
              </div>
              <div className={styles.formGroup}>
                <button className="primary-btn" type="submit">
                  Submit
                </button>
              </div>
            </div>
            <div className={styles.delivered} style={delivered ? { display: "block" } : null}>
              {delivered}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Career;
