import cx from "classnames";
import React from "react";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

function Footer() {
  const navigate = useNavigate();
  return (
    <footer className={styles.footer}>
      <div className={cx(styles.footerTop, "section")}>
        <div className={cx("container", styles.container)}>
          <div className={styles.brand}>
            <a href="#" className={styles.logo}>
              twoace
            </a>
            <p className={styles.text}>
              Two Ace Solutions is a software company on a mission to connect people and businesses
              with meaningful innovation.
            </p>
            <ul className={styles.socialList}>
              <li>
                <a href="#" className={styles.socialLink}>
                  <BsTwitter fontSize={20} />
                </a>
              </li>
              <li>
                <a href="#" className={styles.socialLink}>
                  <BsFacebook fontSize={20} />
                </a>
              </li>

              <li>
                <a href="#" className={styles.socialLink}>
                  <BsInstagram fontSize={20} />
                </a>
              </li>
              <li>
                <a href="#" className={styles.socialLink}>
                  <BsLinkedin fontSize={20} />
                </a>
              </li>
            </ul>
          </div>

          <ul className={styles.footerList}>
            {/* <li>
              <p className={styles.footerListTitle}>Our Links</p>
            </li> */}
            <li>
              <p className={styles.footerLink}>
                <a href="/#">Home</a>
              </p>
            </li>
            <li>
              <p className={styles.footerLink}>
                <a href="/#services">Services</a>
              </p>
            </li>
            <li>
              <p className={styles.footerLink}>
                <a href="/#portfolio">Case Studies</a>
              </p>
            </li>
          </ul>
          <ul className={styles.footerList}>
            {/* <li>
              <p className={styles.footerListTitle}>Our Services</p>
            </li> */}
            <li>
              <p className={styles.footerLink}>
                <a href="/#about">About Us</a>
              </p>
            </li>
            <li>
              <p className={styles.footerLink}>
                <a href="/#industries">Industries</a>
              </p>
            </li>
            <li>
              <p className={styles.footerLink}>
                <a href="/#technologies">Technologies</a>
              </p>
            </li>
          </ul>
          <ul className={styles.footerList}>
            {/* <li>
              <p className={styles.footerListTitle}>Other Links</p>
            </li> */}
            <li>
              <p className={styles.footerLink}>
                <a href="/#contact">Contact us</a>
              </p>
            </li>
            <li>
              <p
                className={styles.footerLink}
                onClick={() => {
                  navigate("/privacypolicy/");
                  window.scrollTo(0, 0);
                }}
              >
                Privacy Policy
              </p>
            </li>
            <li>
              <p
                className={styles.footerLink}
                onClick={() => {
                  navigate("/terms/");
                  window.scrollTo(0, 0);
                }}
              >
                Terms & Conditions
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className={cx(styles.footerBottom)}>
        <div className="container">&copy; 2022 Two Ace Solutions. All rights Reserved.</div>
      </div>
    </footer>
  );
}

export default Footer;
