import React, { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import styles from "./Industries.module.css";
const data = [
  {
    tab: "Healthcare",
    title: "Healthcare and Life Sciences",
    details: `
    Since 2020, Twoace has been delivering a wide range of custom
    and platform-based medical solutions, including patient & hospital
    apps, telemedicine apps, EHR/EMR systems, software for medical
    devices, IoMT solutions, etc. We ensure robust security of the
    designed software and regulation compliance (i.e, HIPAA). Digital care
    is one of the business priority areas of our company.`,
    background: `url(/images/industries/health.svg)`,
  },
  {
    tab: "Finance",
    title: "Finance, Banking and Insurance",
    details: `Our company delivers top-notch BFSI software covering all the phases of the development process, from product ideation and project planning to solution roll-out and support. Twoace has deep expertise in building digital banking solutions, mobile banking apps, lending platforms, investment & trading solutions, etc.`,
    background: `url(/images/industries/finance.svg)`,
  },
  {
    tab: "eCommerce",
    title: "eCommerce, Retail and Consumer Goods",
    details: `Twoace provides both online and offline retail businesses with advanced eCommerce solutions (custom and platform-based). Our retail software products facilitate sales operations, contribute to organizing personalized offers, and help achieve better customer experience.`,
    background: `url(/images/industries/ecommerce.svg)`,
  },
  {
    tab: "Hi-Tech",
    title: "Hi-Tech and Innovations",
    details: `We offer our extensive technology expertise to R&D departments, Independent Software Vendors and Hi-Tech corporations to drive the digital future together. Twoace offers a broad spectrum of IT services, from code audit and software engineering to quality assurance and support. Also, we participate in technically complex, non-linear and knowledge-intensive projects for the development of groundbreaking solutions.`,
    background: `url(/images/industries/hitech.svg)`,
  },
  {
    tab: "eLearning",
    title: "eLearning",
    details: `Twoace is engaged in the implementation of modern technologies in the education field and creates a full set of educational software from scratch. Custom eLearning solutions we develop meet all modern trends, stand out for their user-friendliness, correspond to an interactive educational approach, and have AI capabilities.`,
    background: `url(/images/industries/elearning.svg)`,
  },
  {
    tab: "Automotive",
    title: "Automotive",
    details: `Twoace is engaged in the implementation of modern technologies in the education field and creates a full set of educational software from scratch. Custom eLearning solutions we develop meet all modern trends, stand out for their user-friendliness, correspond to an interactive educational approach, and have AI capabilities.`,
    background: `url(/images/industries/finance.svg)`,
  },
  {
    tab: "Media and Entertainment",
    title: "Media and Entertainment",
    details: `We leverage our technology expertise to develop highly interactive applications for the Media and Entertainment industry. Our Media & Entertainment solutions can help you expand your share in a highly competitive market, maximize ROI, and make significant contributions. We develop software for all possible sectors of the M&E field, including music, video, interpersonal communication, digital media.`,
    background: `url(/images/industries/media.svg)`,
  },
  {
    tab: "Logistics",
    title: "Logistics and Transportation",
    details: `Twoace is engaged in the implementation of modern technologies in the education field and creates a full set of educational software from scratch. Custom eLearning solutions we develop meet all modern trends, stand out for their user-friendliness, correspond to an interactive educational approach, and have AI capabilities.`,
    background: `url(/images/industries/logistics.svg)`,
  },
];

function Industries() {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className={styles.container} id="industries">
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.tabWrapper}>
            <div className={styles.tabContainer}>
              {data.map((item, index) => (
                <div
                  key={index}
                  className={styles.tab}
                  onClick={() => setTabIndex(index)}
                  style={tabIndex === index ? { borderBottom: "2px solid #f6394a" } : null}
                >
                  {item.tab}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <h1 className={styles.title}>{data[tabIndex]?.title}</h1>
              <p className={styles.detail}>{data[tabIndex]?.details}</p>
              <div className={styles.arrowContainer}>
                <AiOutlineArrowLeft
                  style={{ fontSize: "30px" }}
                  className={styles.icon}
                  onClick={() => setTabIndex((prev) => (prev <= 0 ? 0 : prev - 1))}
                />
                <AiOutlineArrowRight
                  style={{ fontSize: "30px" }}
                  className={styles.icon}
                  onClick={() => setTabIndex((prev) => (prev >= data.length - 1 ? prev : prev + 1))}
                />
              </div>
            </div>
            <div
              className={styles.background}
              style={{ backgroundImage: data[tabIndex].background }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Industries;
