import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Contact.module.css";

function Contact() {
  const navigate = useNavigate();
  return (
    <section id="contact">
      <div className={styles.container}>
        <div className="container">
          <div className={styles.content}>
            {" "}
            <div className={styles.subHeading}>
              You have business, we have <span>strategy.</span>
            </div>
            <div className={styles.mainHeading}>GROW YOUR BUSINESS WITH US</div>
            <div
              className="btn"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/career/");
              }}
            >
              Contact Us
            </div>
          </div>
        </div>
        <div className={styles.arrowVector}>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 353 294"
            className={styles.mobileSvg}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <path
                opacity="0.3"
                d="M268.462 96.9809C268.462 96.9809 285.329 111.026 288.232 129.936L302.432 128.288C302.432 128.288 303.06 111.811 284.702 94.1562L268.462 96.9809Z"
                fill="url(#paint0_linear_958_1933)"
              />
              <path
                opacity="0.3"
                d="M-16.2884 289.765L141.567 180.191L232.216 103.729V99.0996C232.216 99.0996 -30.253 282.154 -30.9591 282.389C-31.6652 282.625 -37 293.766 -37 293.766L-16.2884 289.765Z"
                fill="#043847"
              />
              <path
                opacity="0.3"
                d="M232.217 103.729L285.565 96.5102V93.1362L232.295 99.0995L232.217 103.729Z"
                fill="#06546B"
              />
              <path
                opacity="0.3"
                d="M285.564 96.5102C285.564 96.5102 298.745 108.829 302.746 126.483H304.079C304.079 126.483 303.452 111.497 285.564 93.1362V96.5102Z"
                fill="url(#paint1_radial_958_1933)"
              />
            </g>
            <path
              d="M352.955 77.7577C353.74 83.3287 328.399 91.4104 296.391 95.7259C264.382 100.041 237.786 99.0214 237.08 93.4505C236.296 87.8796 261.636 79.7978 293.645 75.4823C325.575 71.1668 352.171 72.1868 352.955 77.7577Z"
              fill="url(#paint2_radial_958_1933)"
            />
            <path
              d="M-15.3259 276.115L139.953 178.443L232.215 100.276V95.7256C232.215 95.7256 -26.6232 269.917 -27.3292 270.152C-28.0353 270.388 -33.3701 281.529 -33.3701 281.529L-15.3259 276.115Z"
              fill="#043847"
            />
            <path
              d="M275.6 83.7207L310.825 77.3651L299.057 71.4019L329.497 77.3651L332.086 87.8793L323.534 83.4068L283.602 92.9794L275.6 83.7207Z"
              fill="url(#paint3_radial_958_1933)"
            />
            <path
              d="M232.216 100.277L285.564 93.1366L284.23 89.998L232.294 95.7259L232.216 100.277Z"
              fill="#06546B"
            />
            <path
              d="M310.511 17.7329L270.971 28.2471L272.383 31.621L311.06 20.8715L310.511 17.7329Z"
              fill="#06546B"
            />
            <path
              d="M285.563 93.1364C285.563 93.1364 299.057 67.1649 299.528 23.7744L295.997 24.4021L283.131 90.3902L285.563 93.1364Z"
              fill="#06546B"
            />
            <path
              d="M287.839 0L271.05 28.247C271.05 28.247 278.974 26.1284 283.132 25.0299C282.975 33.3471 281.013 67.5573 268.618 81.9162C253.555 83.1716 237.237 84.584 219.506 86.0748C201.541 97.3736 127.56 129.23 109.594 140.529C94.3741 143.196 16.0738 171.6 -1.10739 174.582C-5.97147 180.781 -5.57772 242.259 -11.4617 249.713C-17.9733 257.951 -16.8842 261.796 -24.9648 271.918C-7.62675 267.681 -35.1979 272.171 -19.6643 268.404C-4.99357 264.795 125.027 178.443 137.523 174.582C158.941 156.771 213.465 111.34 232.373 95.6474C251.28 93.6073 268.539 91.6457 284.387 89.9195C294.821 71.3236 298.822 39.075 298.273 20.9498C302.353 19.8513 310.669 17.6544 310.669 17.6544L287.839 0Z"
              fill="url(#paint4_linear_958_1933)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_958_1933"
                x1="295.402"
                y1="126.671"
                x2="307.741"
                y2="98.941"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#131731" stop-opacity="0" />
                <stop offset="0.9638" stop-color="#50C0F2" />
              </linearGradient>
              <radialGradient
                id="paint1_radial_958_1933"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(286.778 84.5182) scale(33.239 33.2436)"
              >
                <stop offset="0.2053" stop-color="#06546B" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
              <radialGradient
                id="paint2_radial_958_1933"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(294.969 85.6307) rotate(-7.70658) scale(58.4942 10.1997)"
              >
                <stop stop-color="#06546B" />
                <stop offset="0.0666199" stop-color="#2E7083" />
                <stop offset="0.1581" stop-color="#5F91A0" />
                <stop offset="0.2548" stop-color="#8AAEB9" />
                <stop offset="0.3549" stop-color="#AEC7CF" />
                <stop offset="0.4594" stop-color="#CBDCE0" />
                <stop offset="0.5698" stop-color="#E2EBEE" />
                <stop offset="0.6886" stop-color="#F2F6F8" />
                <stop offset="0.8219" stop-color="#FCFDFD" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
              <radialGradient
                id="paint3_radial_958_1933"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(284.702 128.26) scale(75.3733 75.3837)"
              >
                <stop />
                <stop offset="0.00652629" stop-color="#020202" />
                <stop offset="0.2268" stop-color="#4E4E4E" />
                <stop offset="0.4331" stop-color="#8D8D8D" />
                <stop offset="0.6193" stop-color="#BEBEBE" />
                <stop offset="0.7818" stop-color="#E2E2E2" />
                <stop offset="0.9142" stop-color="#F7F7F7" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
              <linearGradient
                id="paint4_linear_958_1933"
                x1="28.6108"
                y1="206.951"
                x2="293.778"
                y2="12.6276"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#131731" />
                <stop offset="0.9638" stop-color="#50C0F2" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            className={styles.desktopSvg}
            width="100%"
            height="100%"
            viewBox="0 0 1319 1093"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <path
                opacity="0.3"
                d="M1003.83 360.603C1003.83 360.603 1066.55 412.826 1077.34 483.137L1130.14 477.011C1130.14 477.011 1132.47 415.743 1064.21 350.1L1003.83 360.603Z"
                fill="url(#paint0_linear_865_1245)"
              />
              <path
                opacity="0.3"
                d="M-54.9507 1077.42L532 670L869.057 385.693V368.479C869.057 368.479 -106.875 1049.12 -109.5 1050C-112.126 1050.88 -131.962 1092.3 -131.962 1092.3L-54.9507 1077.42Z"
                fill="#043847"
              />
              <path
                opacity="0.3"
                d="M869.058 385.693L1067.42 358.852V346.307L869.349 368.48L869.058 385.693Z"
                fill="#06546B"
              />
              <path
                opacity="0.3"
                d="M1067.42 358.852C1067.42 358.852 1116.43 404.657 1131.31 470.3H1136.27C1136.27 470.3 1133.93 414.576 1067.42 346.307V358.852Z"
                fill="url(#paint1_radial_865_1245)"
              />
            </g>
            <path
              d="M1318 289.124C1320.92 309.839 1226.69 339.889 1107.68 355.935C988.659 371.981 889.769 368.189 887.144 347.474C884.227 326.76 978.449 296.71 1097.47 280.664C1216.19 264.617 1315.08 268.41 1318 289.124Z"
              fill="url(#paint2_radial_865_1245)"
            />
            <path
              d="M-51.3688 1026.67L526 663.5L869.057 372.856V355.935C869.057 355.935 -93.3749 1003.62 -96.0003 1004.5C-98.6257 1005.38 -118.462 1046.8 -118.462 1046.8L-51.3688 1026.67Z"
              fill="#043847"
            />
            <path
              d="M1030.37 311.297L1161.35 287.665L1117.59 265.492L1230.77 287.665L1240.4 326.76L1208.6 310.13L1060.12 345.723L1030.37 311.297Z"
              fill="url(#paint3_radial_865_1245)"
            />
            <path
              d="M869.058 372.856L1067.42 346.307L1062.46 334.637L869.349 355.934L869.058 372.856Z"
              fill="#06546B"
            />
            <path
              d="M1160.18 65.9355L1013.16 105.03L1018.41 117.575L1162.22 77.6055L1160.18 65.9355Z"
              fill="#06546B"
            />
            <path
              d="M1067.42 346.307C1067.42 346.307 1117.59 249.738 1119.34 88.4004L1106.21 90.7344L1058.37 336.096L1067.42 346.307Z"
              fill="#06546B"
            />
            <path
              d="M1075.88 0L1013.45 105.03C1013.45 105.03 1042.92 97.1526 1058.38 93.0682C1057.79 123.994 1050.5 251.196 1004.41 304.587C948.403 309.255 887.727 314.506 821.801 320.049C755 362.061 479.919 480.512 413.117 522.524C356.526 532.443 65.3839 638.057 1.49953 649.143C-16.5864 672.191 -15.1224 900.784 -37.0006 928.5C-61.2125 959.134 -57.1629 973.429 -87.209 1011.07C-22.7412 995.311 -125.258 1012 -67.5 998C-12.9504 984.58 470.5 663.5 516.965 649.143C596.601 582.916 799.34 413.993 869.641 355.643C939.943 348.057 1004.12 340.764 1063.04 334.345C1101.84 265.2 1116.72 145.291 1114.68 77.8972C1129.85 73.8127 1160.77 65.6437 1160.77 65.6437L1075.88 0Z"
              fill="url(#paint4_linear_865_1245)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_865_1245"
                x1="1104"
                y1="471"
                x2="1149.88"
                y2="367.891"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#131731" stop-opacity="0" />
                <stop offset="0.9638" stop-color="#50C0F2" />
              </linearGradient>
              <radialGradient
                id="paint1_radial_865_1245"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1071.94 314.263) scale(123.592 123.609)"
              >
                <stop offset="0.2053" stop-color="#06546B" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
              <radialGradient
                id="paint2_radial_865_1245"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1102.39 318.398) rotate(-7.70658) scale(217.497 37.9251)"
              >
                <stop stop-color="#06546B" />
                <stop offset="0.0666199" stop-color="#2E7083" />
                <stop offset="0.1581" stop-color="#5F91A0" />
                <stop offset="0.2548" stop-color="#8AAEB9" />
                <stop offset="0.3549" stop-color="#AEC7CF" />
                <stop offset="0.4594" stop-color="#CBDCE0" />
                <stop offset="0.5698" stop-color="#E2EBEE" />
                <stop offset="0.6886" stop-color="#F2F6F8" />
                <stop offset="0.8219" stop-color="#FCFDFD" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
              <radialGradient
                id="paint3_radial_865_1245"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1064.21 476.907) scale(280.258 280.297)"
              >
                <stop />
                <stop offset="0.00652629" stop-color="#020202" />
                <stop offset="0.2268" stop-color="#4E4E4E" />
                <stop offset="0.4331" stop-color="#8D8D8D" />
                <stop offset="0.6193" stop-color="#BEBEBE" />
                <stop offset="0.7818" stop-color="#E2E2E2" />
                <stop offset="0.9142" stop-color="#F7F7F7" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
              <linearGradient
                id="paint4_linear_865_1245"
                x1="112"
                y1="769.5"
                x2="1097.97"
                y2="46.9528"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#131731" />
                <stop offset="0.9638" stop-color="#50C0F2" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
}

export default Contact;
