import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import styles from "./Particle.module.css";
import particleConfig from "./particleConfig";

function Particle({ children }) {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };
  return (
    <>
      <Particles params={particleConfig} id="tsparticles" init={particlesInit}></Particles>
      <div className={styles.children}>{children}</div>
    </>
  );
}

export default Particle;
