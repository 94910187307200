const particleConfig = {
  fullScreen: {
    enable: false,
    zIndex: -1,
  },
  particles: {
    number: {
      value: 43,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: "#000000",
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#000000",
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        // src: "img/github.svg",
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: "#000000",
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 6,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: false,
        mode: "repulse",
      },
      onclick: {
        enable: false,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
  //   fullScreen: {
  //     enable: false,
  //     zIndex: -1,
  //   },
  //   particles: {
  //     number: {
  //       value: 10,
  //       density: {
  //         enable: false,
  //         value_area: 800,
  //       },
  //     },
  //     color: {
  //       value: "#000",
  //     },
  //     shape: {
  //       type: "circle",
  //       options: {
  //         sides: 3,
  //       },
  //     },
  //     opacity: {
  //       value: 0.8,
  //       random: false,
  //       anim: {
  //         enable: false,
  //         speed: 1,
  //         opacity_min: 0.1,
  //         sync: false,
  //       },
  //     },
  //     size: {
  //       value: 2,
  //       random: false,
  //       anim: {
  //         enable: false,
  //         speed: 40,
  //         size_min: 0.1,
  //         sync: false,
  //       },
  //     },
  //     rotate: {
  //       value: 0,
  //       random: true,
  //       direction: "clockwise",
  //       animation: {
  //         enable: true,
  //         speed: 5,
  //         sync: false,
  //       },
  //     },
  //     line_linked: {
  //       enable: true,
  //       distance: 600,
  //       color: "#000",
  //       opacity: 0.4,
  //       width: 2,
  //     },
  //     move: {
  //       enable: true,
  //       speed: 2,
  //       direction: "none",
  //       random: false,
  //       straight: false,
  //       out_mode: "out",
  //       attract: {
  //         enable: false,
  //         rotateX: 600,
  //         rotateY: 1200,
  //       },
  //     },
  //   },
  //   interactivity: {
  //     events: {
  //       onhover: {
  //         enable: false,
  //         mode: ["grab"],
  //       },
  //       onclick: {
  //         enable: false,
  //         mode: "bubble",
  //       },
  //       resize: true,
  //     },
  //     modes: {
  //       grab: {
  //         distance: 400,
  //         line_linked: {
  //           opacity: 1,
  //         },
  //       },
  //       bubble: {
  //         distance: 400,
  //         size: 40,
  //         duration: 2,
  //         opacity: 8,
  //         speed: 3,
  //       },
  //       repulse: {
  //         distance: 200,
  //       },
  //       push: {
  //         particles_nb: 4,
  //       },
  //       remove: {
  //         particles_nb: 2,
  //       },
  //     },
  //   },
  //   retina_detect: true,
  //   background: {
  //     color: "#F6F6F8",
  //     image: "",
  //     position: "50% 50%",
  //     repeat: "no-repeat",
  //     size: "cover",
  //   },
};

export default particleConfig;
