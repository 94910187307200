import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Career } from "./pages/Career";
import { ContactForm } from "./pages/ContactForm";
import { Home } from "./pages/Home";
import { Privacy } from "./pages/Privacy";
import { Terms } from "./pages/Terms";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/getintouch/" element={<ContactForm />}></Route>
        <Route path="/career/" element={<Career />}></Route>
        <Route path="/privacypolicy/" element={<Privacy />} />
        <Route path="/terms/" element={<Terms />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
