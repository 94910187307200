import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";

function Navbar() {
  const [openNav, setOpenNav] = useState(false);

  const navigate = useNavigate();

  // const openNavbar = () => {
  //   setOpenNav(true);
  // };

  // const closeNavbar = () => {
  //   setOpenNav(false);
  // };

  const toggleNavbar = () => {
    setOpenNav((prev) => !prev);
  };

  useEffect(() => {
    if (openNav) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }, [openNav]);

  return (
    <div className={cx(styles.header, openNav && styles.active)}>
      <div className={cx("container", styles.container)}>
        <a href="/#" className={styles.logo}>
          twoace
        </a>
        <nav className={cx(styles.navbar, openNav && styles.active)}>
          <ul className={styles.navList}>
            <li>
              <a href="/#about" className={styles.navbarLink} onClick={toggleNavbar}>
                About Us
              </a>
            </li>
            <li>
              <a href="/#services" className={styles.navbarLink} onClick={toggleNavbar}>
                Services
              </a>
            </li>
            <li>
              <a href="/#industries" className={styles.navbarLink} onClick={toggleNavbar}>
                Industries
              </a>
            </li>
            <li>
              <a href="/#portfolio" className={styles.navbarLink} onClick={toggleNavbar}>
                Case Studies
              </a>
            </li>
            <li>
              <a
                className={cx(styles.btn, "btn")}
                onClick={() => {
                  navigate("/getintouch/");
                  toggleNavbar();
                  window.scrollTo(0, 0);
                }}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
        <button className={cx(styles.navToggle, openNav && styles.active)} onClick={toggleNavbar}>
          <span className={cx(styles.line, styles.line1)}></span>
          <span className={cx(styles.line, styles.line2)}></span>
          <span className={cx(styles.line, styles.line3)}></span>
        </button>
      </div>
    </div>
  );
}

export default Navbar;
