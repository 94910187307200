import emailjs from "@emailjs/browser";
import cx from "classnames";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { MdDoubleArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import styles from "./ContactForm.module.css";

function ContactForm() {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    "g-recaptcha-response": "",
  });
  const [error, setError] = useState({});
  const [delivered, setDelivered] = useState();
  const [loading, setLoading] = useState(false);

  const captchaRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    var err = {};
    if (data.name === "") {
      err["name"] = "Name is required";
      setDelivered("");
    }
    if (data.email === "") {
      err["email"] = "Email is required";
      setDelivered("");
    }
    if (data.subject === "") {
      setDelivered("");
      err["subject"] = "Subject is required";
    }
    if (data.phone === "") {
      err["phone"] = "Phone number is required";
    } else if (data.phone.trim().length < 9 || data.phone.trim().length > 10) {
      setDelivered("");
      err["phone"] = "Phone number must be 10 digits";
    }
    if (data.message === "") {
      setDelivered("");
      err["message"] = "Message is required";
    }
    if (data["g-recaptcha-response"] === "") {
      setDelivered("");
      err["captcha"] = "Captcha is required";
    }
    const isValidated = Object.keys(err).length === 0;
    setError(err);
    if (isValidated) {
      setLoading(true);
      emailjs.send("gmail", "template_w7dyu0j", data, "UqlNjbGOEuuYt596p").then(
        (result) => {
          console.log(result.text);
          setData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            "g-recaptcha-response": "",
          });
          setLoading(false);
          setDelivered(
            "We appreciate you for reaching out. We received your message. We will get back to you by next working day."
          );
          window.grecaptcha.reset();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.contentWrapper}>
          <div className={styles.detailsContainer}>
            <h2 className={cx(styles.detailTitle, "title")}>Get in Touch</h2>
            <p className={styles.detailsParagraph}>
              Have something to say? We are here to answer any question you may have. Feel free to
              reach via contact form.
            </p>
            <Link to="/career/" className={styles.career}>
              Let's work together <MdDoubleArrow className={styles.icon} />
            </Link>
          </div>
          <form className={styles.container} autoComplete="off" onSubmit={handleSubmit}>
            <div className={styles.formContainer}>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="name" className={styles.label}>
                    Name*
                  </label>
                  {error.name && <span className={styles.error}>{error.name}</span>}
                </div>
                <input
                  type="text"
                  name="name"
                  id="unique-name"
                  className={styles.input}
                  value={data.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="email" className={styles.label}>
                    Email*
                  </label>
                  {error.email && <span className={styles.error}>{error.email}</span>}
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={styles.input}
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="phone" className={styles.label}>
                    Phone Number*
                  </label>
                  {error.phone && <span className={styles.error}>{error.phone}</span>}
                </div>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  className={styles.input}
                  value={data.phone}
                  onChange={(e) => {
                    setData({ ...data, phone: e.target.value });
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                <div className={styles.errorContainer}>
                  <label htmlFor="subject" className={styles.label}>
                    Subject*
                  </label>
                  {error.subject && <span className={styles.error}>{error.subject}</span>}
                </div>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className={styles.input}
                  value={data.subject}
                  onChange={(e) => {
                    setData({ ...data, subject: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.errorContainer}>
                <label htmlFor="message" className={styles.label}>
                  Your Message*
                </label>
                {error.message && <span className={styles.error}>{error.message}</span>}
              </div>
              <textarea
                type="text"
                name="message"
                id="message"
                className={styles.textarea}
                rows={4}
                value={data.message}
                onChange={(e) => setData({ ...data, message: e.target.value })}
              />
            </div>
            <div className={styles.errorContainer}>
              <ReCAPTCHA
                ref={captchaRef}
                className={styles.captcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={(e) => setData({ ...data, "g-recaptcha-response": e })}
              />
              {error.captcha && <span className={styles.error}>{error.captcha}</span>}
            </div>

            <button className="primary-btn" type="submit">
              Send Message
            </button>
            <div className={styles.delivered} style={delivered ? { display: "block" } : null}>
              {delivered}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
