import React from "react";
import Slider from "react-slick";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import styles from "./CaseStudies.module.css";

const cardData = [
  {
    image: "/images/case/mobilegroup.png",
    title: "Mobile catalog of human pathologies in 3D",
    tags: [
      { title: "Healthcare" },
      { title: "Mobile" },
      { title: "Education" },
      { title: "AR" },
      { title: "Java" },
      { title: "Angular" },
      { title: "3D" },
    ],
  },
  {
    image: "/images/case/scaled.jpg",
    title: "NFT marketplace application",
    tags: [
      { title: "NFT" },
      { title: "PHP" },
      { title: "Vue.js" },
      { title: "eCommerce" },
      { title: "Blockchain" },
      { title: "Web" },
      { title: "Mobile" },
    ],
  },
  {
    image: "/images/case/yes.png",
    title: "E-Payment ecosystem with mobile banking",
    tags: [
      { title: "Banking" },
      { title: "Payment" },
      { title: "Mobile" },
      { title: "Ios" },
      { title: "Andriod" },
      { title: "Java" },
      { title: "3D" },
    ],
  },
  {
    image: "/images/case/ai.png",
    title: "Business intelligence system for plants",
    tags: [
      { title: "Manufacturing" },
      { title: "Business Intelligence" },
      { title: "Analytics" },
      { title: "Machine Learning" },
      { title: "Web" },
      { title: "Javascript" },
      { title: "Angular" },
      { title: ".NET" },
    ],
  },
  {
    image: "/images/case/operation.png",
    title: "Truck fleet management system for transportation company",
    tags: [
      { title: "Logistics & Transportation" },
      { title: ".NET" },
      { title: "Data Management" },
      { title: "React" },
      { title: "Typescript" },
    ],
  },
];

function CaseStudies() {
  const { width } = useWindowDimensions();
  const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          speed: 500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          speed: 500,
        },
      },
    ],
  };

  return (
    <div className={styles.container} id="portfolio">
      <div className="container">
        <h1 className="title">Case Studies</h1>
        {width < 1300 ? (
          <div className={styles.mobileWrapper}>
            {" "}
            <Slider {...settings} className={styles.mobileCardContainer}>
              {cardData.map((item, index) => (
                <div key={index}>
                  <div className={styles.mobileCard}>
                    <div className={styles.mobileImageContainer}>
                      <img className={styles.mobileImage} src={item.image} alt="card" />
                    </div>
                    <div className={styles.mobileContent}>
                      <h3 className={styles.mobileTitle}>{item.title}</h3>
                      <div className={styles.tagContainer}>
                        {item.tags.map((tag, index) => (
                          <div key={index} className={styles.tag}>
                            {tag.title}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className={styles.desktopWrapper}>
            <div className={styles.desktopCardContainer}>
              {cardData.map((item, index) => (
                <div className={styles.desktopCard}>
                  <div className={styles.desktopImageContainer}>
                    <img className={styles.desktopImage} src={item.image} alt="card" />
                  </div>
                  <div className={styles.desktopContent}>
                    <h1 className={styles.desktopTitle}>{item.title}</h1>
                    <div className={styles.desktoptagContainer}>
                      {item.tags.map((tag, index) => (
                        <div className={styles.desktopTag} key={index}>
                          {tag.title}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CaseStudies;
