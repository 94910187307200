import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CaseStudies from "../../sections/Home/CaseStudies/CaseStudies";
import { Contact } from "../../sections/Home/Contact";
import { Industries } from "../../sections/Home/Industries";
import { Landing } from "../../sections/Home/Landing";
import { Mission } from "../../sections/Home/Mission";
import { Services } from "../../sections/Home/Services";
import { Technology } from "../../sections/Home/Technologies";

function Home() {
  const location = useLocation();

  useEffect(() => {
    const id = location.hash;
    if (id) document.querySelector(id).scrollIntoView();
  }, []);
  return (
    <div>
      <Landing />
      <Services />
      <CaseStudies />
      <Technology />
      <Industries />
      <Mission />
      <Contact />
    </div>
  );
}

export default Home;
