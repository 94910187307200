import React from "react";
import styles from "./Privacy.module.css";

function Privacy() {
  return (
    <div className={styles.container} id="privacy">
      <div className="container">
        <h3 className={styles.title}>Privacy Policy</h3>
        <div className={styles.privacy}>
          <h4 className={styles.subtitle}>Privacy Policy for Two Ace Solutions Pvt Ltd</h4>
          <p className={styles.paragraph}>
            At Two Ace Solutions, accessible from twoacesolutions.com.np, one of our main priorities
            is the privacy of our visitors. This Privacy Policy document contains types of
            information that is collected and recorded by Two Ace Solutions and how we use it.
          </p>
          <p className={styles.paragraph}>
            If you have additional questions or require more information about our Privacy Policy,
            do not hesitate to contact us.
          </p>
          <p className={styles.paragraph}>
            This Privacy Policy applies only to our online activities and is valid for visitors to
            our website with regards to the information that they shared and/or collect in Two Ace
            Solutions. This policy is not applicable to any information collected offline or via
            channels other than this website.
          </p>
        </div>
        <div className={styles.consent}>
          <h4 className={styles.subtitle}>Consent</h4>
          <p className={styles.paragraph}>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </p>
        </div>
        <div className={styles.information}>
          <h4 className={styles.subtitle}>Information we collect</h4>
          <p className={styles.paragraph}>
            The personal information that you are asked to provide, and the reasons why you are
            asked to provide it, will be made clear to you at the point we ask you to provide your
            personal information.
          </p>
          <p className={styles.paragraph}>
            If you contact us directly, we may receive additional information about you such as your
            name, email address, phone number, the contents of the message and/or attachments you
            may send us, and any other information you may choose to provide.
          </p>
        </div>
        <div className={styles.info}>
          <h4 className={styles.subtitle}>How we use your information</h4>
          <ul className={styles.list}>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our partners, including for
              customer service, to provide you with updates and other information relating to the
              website, and for marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
        </div>
        <div className={styles.log}>
          <h4 className={styles.subtitle}>Log Files</h4>
          <p className={styles.paragraph}>
            Two Ace Solutions follows a standard procedure of using log files. These files log
            visitors when they visit websites. All hosting companies do this and a part of hosting
            services' analytics. The information collected by log files include internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are not linked to any
            information that is personally identifiable. The purpose of the information is for
            analyzing trends, administering the site, tracking users' movement on the website, and
            gathering demographic information.
          </p>
        </div>
        <div className={styles.cookies}>
          <h4 className={styles.subtitle}>Cookies and Web Beacons</h4>
          <p className={styles.paragraph}>
            Like any other website, Two Ace Solutions uses 'cookies'. These cookies are used to
            store information including visitors' preferences, and the pages on the website that the
            visitor accessed or visited. The information is used to optimize the users' experience
            by customizing our web page content based on visitors' browser type and/or other
            information.
          </p>
        </div>
        <div className={styles.children}>
          <h4 className={styles.subtitle}>Children's Information</h4>
          <p className={styles.paragraph}>
            Another part of our priority is adding protection for children while using the internet.
            We encourage parents and guardians to observe, participate in, and/or monitor and guide
            their online activity.
          </p>
          <p className={styles.paragraph}>
            Two Ace Solutions does not knowingly collect any Personal Identifiable Information from
            children under the age of 13. If you think that your child provided this kind of
            information on our website, we strongly encourage you to contact us immediately and we
            will do our best efforts to promptly remove such information from our records.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
