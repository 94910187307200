import React from "react";
import styles from "./Mission.module.css";

function Mission() {
  return (
    <div className={styles.container} id="about">
      <div className={styles.mission}>
        <div className={styles.background}>
          <div className={styles.flag}>
            <span className={styles.flagSpan}></span>
          </div>
          <div className={styles.full}></div>
        </div>
        <div className="container">
          <div className={styles.content}>
            <div className={styles.leftContent}>Our Mission</div>
            <div className={styles.rightContent}>
              <h3 className={styles.title}>We develop the world</h3>
              <p className={styles.subtitle}>
                Twoace is not only a software development company but also a friendly community of
                high-grade engineers striving to change the world through digital innovation.
                Leveraging all our skills, knowledge and experience, we help our clients reach new
                heights and make the world a little better.
                <br />
                Our corporate mission is simple to formulate yet challenging to complete. We create
                IT solutions. Together with our clients, we make the world a more comfortable and
                better place for everyone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
