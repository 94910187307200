import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Particle } from "../../../components/Particle";
import styles from "./Landing.module.css";

const data = [
  {
    title1: "Data Management",
    title2: "and analytics",
    subtitle: "Get your data well-organized, securely stored, and analyzed to boost your business",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <rect x="9" y="3" width="22" height="34" rx="3" stroke="#2E2E2E" stroke-width="2" />
        <rect x="17.5" y="5.5" width="5" height="1" rx="0.5" stroke="#2E2E2E" />
        <rect x="12.5" y="21.5" width="15" height="1" rx="0.5" stroke="#2E2E2E" />
        <rect x="12.5" y="24.5" width="10" height="1" rx="0.5" stroke="#2E2E2E" />
        <rect x="9.5" y="28.5" width="21" height="1" rx="0.5" stroke="#2E2E2E" />
        <rect x="13" y="9" width="14" height="9" rx="1" stroke="#C63031" stroke-width="2" />
        <path d="M27 9L13 18" stroke="#C63031" stroke-width="2" />
        <path d="M13 9L27 18" stroke="#C63031" stroke-width="2" />
        <rect x="19" y="32" width="2" height="2" rx="1" stroke="#2E2E2E" stroke-width="2" />
      </svg>
    ),
  },
  {
    title1: "Business",
    title2: "Intelligence",
    subtitle: "Extract insights from data and benefit from smarter data-driven decisions",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <g opacity="0.8">
          <rect
            x="8"
            y="34"
            width="23"
            height="28"
            rx="2"
            transform="rotate(-90 8 34)"
            stroke="#2E2E2E"
            stroke-width="2"
          />
          <rect x="8" y="11" width="28" height="5" rx="2.5" stroke="#2E2E2E" stroke-width="2" />
          <path d="M26 28L29 25L26 22" stroke="#C63031" stroke-width="2" />
          <path d="M17 28L14 25L17 22" stroke="#C63031" stroke-width="2" />
          <path d="M19 31L24 20" stroke="#C63031" stroke-width="2" />
        </g>
      </svg>
    ),
  },
  {
    title1: "Full-cycle",
    title2: "software development",
    subtitle: "We deliver high-quality end-to-end software development services",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <g opacity="0.8">
          <rect
            x="28.3896"
            y="15"
            width="2.75757"
            height="4.66059"
            rx="1.24504"
            stroke="#2E2E2E"
            stroke-width="2"
          />
          <mask id="path-2-inside-1_195_7009" fill="white">
            <path d="M24.5352 24.0181C24.5352 22.8921 25.1646 21.8528 26.2185 21.4563C27.2596 21.0646 28.6132 20.6606 29.7685 20.6606C30.9237 20.6606 32.2774 21.0646 33.3185 21.4563C34.3724 21.8528 35.0018 22.8921 35.0018 24.0181V28.2727H24.5352V24.0181Z" />
          </mask>
          <path
            d="M35.0018 28.2727V30.2727H37.0018V28.2727H35.0018ZM24.5352 28.2727H22.5352V30.2727H24.5352V28.2727ZM33.3185 21.4563L32.6142 23.3282L33.3185 21.4563ZM26.2185 21.4563L25.5142 19.5844L26.2185 21.4563ZM33.0018 24.0181V28.2727H37.0018V24.0181H33.0018ZM35.0018 26.2727H24.5352V30.2727H35.0018V26.2727ZM26.5352 28.2727V24.0181H22.5352V28.2727H26.5352ZM26.9228 23.3282C27.9159 22.9545 28.9895 22.6606 29.7685 22.6606V18.6606C28.237 18.6606 26.6033 19.1746 25.5142 19.5844L26.9228 23.3282ZM29.7685 22.6606C30.5475 22.6606 31.6211 22.9545 32.6142 23.3282L34.0228 19.5844C32.9337 19.1746 31.3 18.6606 29.7685 18.6606V22.6606ZM37.0018 24.0181C37.0018 22.1741 35.9585 20.3127 34.0228 19.5844L32.6142 23.3282C32.7862 23.3929 33.0018 23.6101 33.0018 24.0181H37.0018ZM26.5352 24.0181C26.5352 23.6101 26.7507 23.3929 26.9228 23.3282L25.5142 19.5844C23.5785 20.3127 22.5352 22.1741 22.5352 24.0181H26.5352Z"
            fill="#2E2E2E"
            mask="url(#path-2-inside-1_195_7009)"
          />
          <rect
            x="6"
            y="33"
            width="24"
            height="32"
            rx="2"
            transform="rotate(-90 6 33)"
            stroke="#2E2E2E"
            stroke-width="2"
          />
          <path
            d="M21.1292 18.25C20.5587 17.2619 19.7381 16.4413 18.75 15.8708C17.7619 15.3003 16.641 15 15.5 15L15.5 21.5L21.1292 18.25Z"
            fill="#C63031"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M15.5 15C14.359 15 13.2381 15.3003 12.25 15.8708C11.2619 16.4413 10.4413 17.2619 9.87083 18.25C9.30034 19.2381 9 20.359 9 21.5C9 22.641 9.30034 23.7619 9.87083 24.75C10.4413 25.7381 11.2619 26.5587 12.25 27.1292C13.2381 27.6997 14.359 28 15.5 28C16.641 28 17.7619 27.6997 18.75 27.1292C19.7381 26.5587 20.5587 25.7381 21.1292 24.75L15.5 21.5L15.5 15Z"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M15.5 15C14.0691 15 12.6782 15.4721 11.5431 16.3432C10.4079 17.2143 9.59182 18.4356 9.22148 19.8177C8.85114 21.1998 8.94721 22.6655 9.49478 23.9874C10.0424 25.3094 11.0108 26.4137 12.25 27.1292C13.4892 27.8446 14.9298 28.1312 16.3484 27.9444C17.7671 27.7576 19.0844 27.108 20.0962 26.0962C21.108 25.0844 21.7576 23.7671 21.9444 22.3484C22.1312 20.9298 21.8446 19.4892 21.1292 18.25L15.5 21.5L15.5 15Z"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M15.5 15C14.6464 15 13.8012 15.1681 13.0126 15.4948C12.2239 15.8214 11.5074 16.3002 10.9038 16.9038C10.3002 17.5074 9.82144 18.2239 9.49478 19.0126C9.16813 19.8012 9 20.6464 9 21.5C9 22.3536 9.16813 23.1988 9.49478 23.9874C9.82144 24.7761 10.3002 25.4926 10.9038 26.0962C11.5074 26.6998 12.2239 27.1786 13.0126 27.5052C13.8012 27.8319 14.6464 28 15.5 28L15.5 21.5L15.5 15Z"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <line x1="11" y1="36" x2="33" y2="36" stroke="#2E2E2E" stroke-width="2" />
        </g>
      </svg>
    ),
  },
  {
    title1: "IT staff",
    title2: "augmentation",
    subtitle: "Enhance your in-house IT department with our experienced professionals",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <g opacity="0.8">
          <rect x="11" y="14" width="22" height="8" rx="3" stroke="#2E2E2E" stroke-width="2" />
          <rect x="17" y="7" width="10" height="3" rx="1.5" stroke="#2E2E2E" stroke-width="2" />
          <rect x="16" y="26" width="12" height="4" rx="1" stroke="#2E2E2E" stroke-width="2" />
          <path
            d="M35 18H37C38.1046 18 39 18.8954 39 20V26C39 27.1046 38.1046 28 37 28H30"
            stroke="#2E2E2E"
            stroke-width="2"
          />
          <rect x="20" y="35" width="4" height="4" rx="2" stroke="#C63031" stroke-width="2" />
        </g>
      </svg>
    ),
  },
];

function Landing() {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 625,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.container} id="/">
      <div className="container">
        <Particle>
          {" "}
          <h1 className={styles.title}>Hire dedicated software developers within 72 hours</h1>
          <button
            className={cx(styles.btn, "primary-btn")}
            onClick={() => {
              navigate("/career/");
              window.scrollTo(0, 0);
            }}
          >
            Lets Talk
          </button>
        </Particle>

        <div className={styles.services}>
          <Slider {...settings} className={styles.cardContainer}>
            {data.map((item, index) => (
              <div>
                <div className={styles.card} key={index}>
                  <div className={styles.titleContainer}>
                    <div className={styles.icon}>{item.icon}</div>
                    <div className={styles.cardTitle}>
                      {item.title1}
                      <br />
                      {item.title2}
                    </div>
                  </div>
                  <div className={styles.cardSubtitle}>{item.subtitle}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Landing;
