import React, { useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import styles from "./Services.module.css";

const data = [
  {
    title: "Dedicated Team",
    list: [
      {
        title: "Front-end developers",
        subtitle:
          "Extend your IT team with our skilled front-end developers keeping fully abreast with the latest tech trends",
      },
      {
        title: "Back-end developers",
        subtitle:
          "We bring together top-notch back-end experts with deep knowledge of the server-side development process",
      },
      {
        title: "Mobile developers",
        subtitle:
          "High-grade development team with vast expertise in native and cross-platform mobile applications for hire",
      },
      {
        title: "UI/UX designers",
        subtitle: "Our UI/UX design team inspires user engagement and interaction",
      },
      {
        title: "QA engineers",
        subtitle: "Our automation and manual testers ensure the flawless product quality",
      },
      {
        title: "3D designers",
        subtitle: "Visualize and animate your ideas in 3D with the help of our creative team",
      },
    ],
  },
  {
    title: "Custom software development",
    list: [
      {
        title: "Web",
        subtitle:
          "We develop and implement custom web-based solutions tailored to particular business and technological requirements. Twoace offers web application development services bringing in vast technology and industry expertise",
      },
      {
        title: "Mobile",
        subtitle:
          "Top-notch iOS & Android mobile apps based on the latest technologies and global trends",
      },
      {
        title: "Cloud",
        subtitle: "We develop cloud-based apps and transfer client-server software to the cloud",
      },
      {
        title: "Data engineering",
        subtitle:
          "We turn data into meaningful insights to improve business strategy and performance",
      },
    ],
  },
  {
    title: "IT Consulting & Support",
    list: [
      {
        title: "IT Audit",
        subtitle: "We analyze businesses and projects to identify weaknesses and plan optimization",
      },
      {
        title: "IT Consulting",
        subtitle: "Our IT experts can help you optimize your business and get the most value",
      },
      {
        title: "Solution Architecture",
        subtitle: "We build high-level solution architectures to be a basement of digital systems",
      },
      {
        title: "R&D",
        subtitle: "Our team develops innovative solutions using the latest technology findings",
      },
      {
        title: "Prototyping",
        subtitle: "We deliver highly detailed prototypes under tight deadlines",
      },
      {
        title: "Maintenance",
        subtitle:
          "Software maintenance services to ensure its robust security and high performance",
      },
    ],
  },
  {
    title: "Digital transformation",
    list: [
      {
        title: "Enterprise Resource Planning (ERP)",
        subtitle: "Custom ERP systems, ensuring robust security and fast user adoption",
      },
      {
        title: "Customer relationship management (CRM)",
        subtitle:
          "CRM development services to open up game-changing sales and marketing opportunities",
      },
      {
        title: "Business Process Management (BPM)",
        subtitle: "We build high-level solution architectures to be a basement of digital systems",
      },
      {
        title: "Document & Content Management (DMS, CMS)",
        subtitle:
          "Custom software platforms for document workflow enhancement and content management",
      },
      {
        title: "Data Analytics and Visualization",
        subtitle:
          "We facilitate uncovering business insights, making the best use of the available data",
      },
      {
        title: "Human Resource Management (HRM)",
        subtitle: "Electronic systems to facilitate HR department’s daily operations",
      },
    ],
  },
];

function Services() {
  const [openTabIndex, setOpenTabIndex] = useState(0);
  const [itemOpen, setItemOpen] = useState("");
  const { width } = useWindowDimensions();
  return (
    <div className={styles.container} id="services">
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className="title">Services</h2>
          {width < 1300 ? (
            <div className={styles.mobileContent}>
              {data.map((item, index) => (
                <div
                  className={styles.serviceList}
                  style={openTabIndex === index ? { borderLeft: "1px solid red" } : null}
                  key={index}
                >
                  <h2
                    className={styles.serviceTitle}
                    style={
                      openTabIndex === index
                        ? { borderLeft: "2px solid red", color: "#f6394a" }
                        : null
                    }
                    onClick={() => setOpenTabIndex(index)}
                  >
                    {item.title}
                  </h2>
                  <ul key={index}>
                    {openTabIndex === index &&
                      item.list.map((subitem, index) => (
                        <li
                          className={styles.serviceItem}
                          onClick={() => setItemOpen(subitem.title)}
                          key={index}
                        >
                          {subitem.title}
                          {itemOpen === subitem.title && (
                            <p className={styles.itemSubtitle}>{subitem.subtitle}</p>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.desktopContent}>
              <div className={styles.leftContent}>
                {data.map((item, index) => (
                  <div className={styles.desktopWrapper}>
                    <div key={index}>
                      <h2
                        className={styles.serviceTitle}
                        style={openTabIndex === index ? { borderLeft: "2px solid red" } : null}
                        onClick={() => setOpenTabIndex(index)}
                      >
                        {item.title}
                      </h2>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                {openTabIndex !== null && (
                  <div className={styles.desktopServiceList}>
                    <h1 className={styles.desktopTitle}>{data[openTabIndex].title}</h1>
                    <div className={styles.desktopCardContainer}>
                      {data[openTabIndex].list.map((item, index) => (
                        <div className={styles.desktopCard} key={index}>
                          <h2 className={styles.desktopCardTitle}>{item.title}</h2>
                          <p className={styles.desktopCardSubtitle}>{item.subtitle}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Services;
